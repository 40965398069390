import { Link } from "react-router-dom";
const apiUrl=process.env.REACT_APP_API_URL;
export default function UserPopoverAvatar({ userid, user }) {
    return <>

        <button data-popover-target={`popover-user-profile${userid}`} type="button" className="text-white focus:ring-0 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 animate-bounce mt-2">
            <img className="w-10 h-10 p-1 rounded-full ring-2 ring-gray-300 dark:ring-gray-500" src={`${apiUrl}${user?.avatar}`} alt="Bordered avatar" />
        </button>
        <div data-popover id={`popover-user-profile${userid}`} role="tooltip" className="absolute z-50 invisible inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:bg-gray-800 dark:border-gray-600">
            <div className="p-3">
                <div className="flex items-center justify-between mb-2">
                    <Link to={`/profile/${user?.user.id}`}>
                        <img className="w-10 h-10 rounded-full" src={`${apiUrl}${user?.avatar}`} alt={`${user?.user.first_name} ${user?.user.last_name}`} />
                    </Link>
                    <div>
                        <button type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-xs px-3 py-1.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Chat</button>
                    </div>
                </div>
                <p className="text-base font-semibold leading-none text-gray-900 dark:text-white">
                    <Link to={`/profile/${user?.user.id}`}>{user?.user.first_name} {user?.user.last_name}</Link>
                </p>
                <p className="mb-3 text-sm font-normal">
                    <Link to={`/profile/${user?.user.id}`} className="hover:underline">@{user?.user.username}</Link>
                </p>
                <p className="mb-4 text-sm">Seller with passion</p>
                <ul className="flex text-sm">
                    <li className="me-2">
                        <Link to={`/profile/${user?.user.id}/products`} className="hover:underline">
                            <span className="font-semibold text-gray-900 dark:text-white">{user?.product_count} </span>
                            <span>Products</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={`/profile/${user?.user.id}/blogs`} className="hover:underline">
                            <span className="font-semibold text-gray-900 dark:text-white">{user?.blog_count} </span>
                            <span>Blogs</span>
                        </Link>
                    </li>
                </ul>
            </div>
            <div data-popper-arrow />
        </div>
    </>
}