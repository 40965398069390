import { useState } from "react";
import RatingFilterForm from "../Product/RatingsFilterForm";

export default function CommentForm() {
    const [ratingStars, setRatingStars]=useState(0)
    return <>
        <div className="m-4 p-3 bg-lime-50">
            <form action="#">
                <h3 className="mb-4 text-lg font-medium leading-none text-gray-900 dark:text-white">Write Your Comment about this product</h3>
                <div className="grid gap-4 mb-4 sm:grid-cols-1">
                    <div>
                        <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Comment Title</label>
                        <input type="text" name="title" id="title" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Thinking to buy another one!" required />
                    </div>
                    <div>
                        <label htmlFor="comment" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Comment body</label>
                        <textarea name="comment" rows={10} id="comment" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Type your comment..." required />
                    </div>
                    <div>
                        <div className="flex items-center mb-1 space-x-1 rtl:space-x-reverse">
                            <p className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Stars:</p>
                            <span className=" animate-bounce flex">
                            <RatingFilterForm addRatings={(val)=>{setRatingStars(val);}} ratings={ratingStars}/>

                            </span>
                        </div>
                    </div>

                </div>
                <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    Post
                </button>
            </form>
        </div>
    </>
}