import { Link } from "react-router-dom"

export default function PasswordResetDone() {
    return <>

        <div className="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8 text-center">
            <h1 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Almost there
            </h1>
            <p className="font-light text-gray-500 dark:text-gray-400">Check your inbox, we have sent you a link to reset your password!</p>

            <p className="p-3">
                <Link to='/login' type="submit" className="w-full text-white bg-lime-600 hover:bg-lime-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-lime-600 dark:hover:bg-lime-700 dark:focus:ring-primary-800">Go back to Login page </Link>
            </p>

        </div>

    </>
}