import { Link } from "react-router-dom";

export default function AboutUs() {
    return <>
        <section className="bg-white dark:bg-gray-900 pt-20">
            <div className="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16 lg:px-12">

                <form className="max-w-lg mx-auto">
                    <div className="flex pb-5">
                        <label htmlFor="search-dropdown" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Category</label>
                        <button id="dropdown-button" data-dropdown-toggle="dropdown" className="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600" type="button">All categories <svg className="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="m1 1 4 4 4-4" />
                        </svg>
                        </button>
                        <div id="dropdown" className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                            <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdown-button">
                                <li>
                                    <button type="button" className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Plastic</button>
                                </li>
                                <li>
                                    <button type="button" className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Metals</button>
                                </li>
                                <li>
                                    <button type="button" className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Tiles</button>
                                </li>
                                <li>
                                    <button type="button" className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Glass</button>
                                </li>
                            </ul>
                        </div>
                        <div className="relative w-full">
                            <input type="search" id="search-dropdown" className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-e-lg border-s-gray-50 border-s-2 border border-gray-300 focus:ring-lime-500 focus:border-lime-500 dark:bg-gray-700 dark:border-s-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-lime-500" placeholder="Type your search..." required />
                            <button type="submit" className="absolute top-0 end-0 p-2.5 text-sm font-medium h-full text-white bg-lime-700 rounded-e-lg border border-lime-700 hover:bg-lime-800 focus:ring-4 focus:outline-none focus:ring-lime-300 dark:bg-lime-600 dark:hover:bg-lime-700 dark:focus:ring-lime-800">
                                <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                                <span className="sr-only">Search</span>
                            </button>
                        </div>
                    </div>
                </form>

                <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white">Welcome to the waste marketplace.
                </h1>
                <p className="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">
                    Our mission is to connect individuals and businesses looking to dispose of or acquire waste materials such as plastic, broken tiles, and scrap metals in order to promote the circular economy and reduce environmental impact. Together, we can work towards a greener, cleaner future for generations to come.
                </p>
                <div className="flex flex-col mb-8 space-y-4 lg:mb-16 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
                    <Link to='/#shopnow' className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white bg-lime-700 rounded-lg hover:bg-lime-800 focus:ring-4 focus:ring-lime-300 dark:focus:ring-lime-900">
                        Get Started
                        <svg className="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
                    </Link>
                    <Link to="/signup" className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                        Register
                    </Link>
                </div>
                <div className="px-4 mx-auto text-center md:max-w-screen-md lg:max-w-screen-lg lg:px-36">
                    <span className="font-semibold text-gray-400 uppercase">Our Mission</span>
                    <div className="flex flex-wrap items-center justify-center mt-8 text-gray-500 sm:justify-between">
                        <a href="#!" className="mb-5 text-3xl font-bold mr-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400">
                            <div>
                                <ion-icon name="remove-circle-outline" /> Reducing 
                            </div>

                        </a>
                        <a href="#!" className="mb-5 text-3xl font-bold mr-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400">
                            <div className="flex">
                                <ion-icon name="refresh-outline" /> Reusing
                            </div>
                        </a>
                        <a href="#!" className="mb-5 text-3xl font-bold mr-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400">
                            <div>
                                <ion-icon name="trash-outline" /> Recycling
                            </div>

                        </a>
                    </div>
                </div>
            </div>
        </section>

        <section className="bg-white dark:bg-gray-900">
        <div className="items-center max-w-screen-xl gap-16 px-4 py-8 mx-auto lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
          <div className="text-gray-500 sm:text-lg dark:text-gray-400">
            <h2 className="mb-4 text-4xl font-extrabold tracking-tight text-gray-900 dark:text-white">Who are we?</h2>
            <p className="mb-4">

              WasteMarketPlace is Nairobi's premiere marketplace for buying and selling waste materials for recycling. Our platform connects individuals and businesses looking to responsibly dispose of items such as plastic, broken tiles, and scrap metals with those who can repurpose and recycle these materials.

              Founded by <a className='text-lime-500' href='https://arthurom254.github.io/'> Arthur</a>, WasteMarket aims to make it easy and convenient for you to not only declutter your space but also contribute to a more sustainable future. By buying and selling waste materials on our platform, you are not only reducing waste but also supporting the circular economy.

              Join WasteMarket today and start turning your tr<b>ash</b> into tr<b>easure</b>. Together, we can make a positive impact on the environment and create a greener, cleaner future for Nairobi and beyond.

            </p>
          </div>
          <div className="grid grid-cols-2 gap-4 mt-8">
            <img className="w-full rounded-lg" src="https://images.unsplash.com/photo-1605600659908-0ef719419d41?q=80&w=1472&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="about waste market kenya 1" />
            <img className="w-full mt-4 rounded-lg lg:mt-10" src="https://images.unsplash.com/photo-1612965110667-4175024b0dcc?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHdhc3RlfGVufDB8fDB8fHww" alt="about waste market kenya 1" />
          </div>
        </div>
      </section>
    </>
}