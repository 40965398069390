import { format } from "date-fns";
export default function InboxList({openMsgFunc,messagesInbox,currentUser }){
  
    return <>  
        <div className="py-3 px-5">
          <h3 className="text-xs font-semibold uppercase text-gray-400 mb-1">Chats</h3>
          <div className="divide-y divide-gray-200">
            {messagesInbox?.map((msg, key)=>(
              currentUser===msg.sender ?
              <button key={key} onClick={()=>{openMsgFunc(true,msg?.receiver); localStorage.setItem('to',msg?.receiver);}} className="w-full text-left py-2 focus:outline-none focus-visible:bg-indigo-50">
              <div className="flex items-center">
                <img className="rounded-full items-start flex-shrink-0 mr-3" src={msg?.receiver_profile.avatar} width={40} height={40} alt="John arthur" />
                <div className="w-full">
                  <h4 className="text-sm font-semibold text-gray-900 flex" style={{textOverflow:"ellipsis", whiteSpace:"nowrap",maxWidth:"200px", overflow:"hidden"}}>{msg?.receiver_profile.user.first_name} {msg?.receiver_profile.user.last_name}</h4>
                  <div className="text-[13px] flex justify-between"> 
                  <span>{msg?.message}</span>
                  <span>{format(msg.date, 'd MMM hh:mm')}</span> 
                  </div>
                </div>
              </div>
            </button>:
            
              <button key={key} onClick={()=>{openMsgFunc(true,msg?.sender);localStorage.setItem('to',msg?.sender);}} className="w-full text-left py-2 focus:outline-none focus-visible:bg-indigo-50">
              <div className="flex items-center">
                <img className="rounded-full items-start flex-shrink-0 mr-3" src={msg?.sender_profile.avatar} width={40} height={40} alt="John arthur" />
                <div className="w-full">
                  <h4 className="text-sm font-semibold text-gray-900 flex" style={{textOverflow:"ellipsis", whiteSpace:"nowrap",maxWidth:"200px", overflow:"hidden"}}>{msg?.sender_profile.user.first_name} {msg?.sender_profile.user.last_name} {parseInt(msg.msg_count)>0&&<span className="border text-[13px] rounded-full w-4 h-4 flex justify-center items-center	mx-3 bg-red-950 text-white">{msg.msg_count}</span>}</h4>
                  <div className="text-[13px] flex justify-between"> 
                  <span>{msg?.message}</span>
                  <span>{format(msg.date, 'd MMM hh:mm')}</span> 
                  
                  </div>
                </div>
              </div>
            </button>
            ))}
            
          </div>
        </div>


    </>
}