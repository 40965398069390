import React, { useEffect, useState } from 'react';
import axios from 'axios';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import Select from 'react-select';
import { useNavigate,useParams, Navigate } from 'react-router-dom';
const AddProductForm = () => {
    const isAuthenticated = useIsAuthenticated()
    const [productCategory, setProductCategory] = useState([])
    const navigate=useNavigate();
    const {userid} = useParams();
    
    useEffect(()=>{        
        axios.get(`${process.env.REACT_APP_API_URL}/api/product/category/`).then(data=>{
        console.log(data.data);
        setProductCategory(data.data);
        }).catch(e=>{});
    },[])

    const [productData, setProductData] = useState({
        title: '',
        quantity: '',
        price: '',
        location: '',
        category: [],
        images: [],
        descriptions: [{ title: '', body: '' }]
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProductData({ ...productData, [name]: value });
    };

    const handleCategoryChange = (selectedOptions) => {
        const category = selectedOptions.map(option => option.value);
        setProductData({ ...productData, category });
    };
    
    const handleDescriptionChange = (e, index) => {
        const { name, value } = e.target;
        const descriptions = [...productData.descriptions];
        descriptions[index][name] = value;
        setProductData({ ...productData, descriptions });
    };
    
    const addDescriptionField = () => {
        setProductData({ ...productData, descriptions: [...productData.descriptions, { title: '', body: '' }] });
    };
    
    const removeDescriptionField = (index) => {
        const descriptions = [...productData.descriptions];
        descriptions.splice(index, 1);
        setProductData({ ...productData, descriptions });
    };
    const handleImageChange = (e) => {
        const images = e.target.files;
        setProductData({ ...productData, images });
    };
    
const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const formData = new FormData();
        formData.append('title', productData.title);
        formData.append('quantity', productData.quantity);
        formData.append('price', productData.price);
        formData.append('location', productData.location);

        // formData.append('category', productData.category);
        // formData.append('category', productData.category.join()); 

        for (let i = 0; i < productData.category.length; i++) {
            formData.append(`category`, productData.category[i]);
        }
        for (let i = 0; i < productData.images.length; i++) {
            formData.append(`images`, productData.images[i]);
        }
        productData.descriptions.forEach((description, index) => {
            formData.append(`descriptions[title]`, description.title);
            formData.append(`descriptions[body]`, description.body);
        });
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/product/new/` , formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        console.log(response.data)
        navigate(`/profile/${userid}`)
        
    } catch (error) {
        console.error('Error:', error );
    }
};

    return <>{
        isAuthenticated()?<>
        <form  className='py-10 mx-auto ' onSubmit={handleSubmit} encType="multipart/form-data">
            <input type="text" name="title" value={productData.title} onChange={handleInputChange} placeholder="Title" />
            {console.log("Auth", isAuthenticated() )}
            <input type="number" name="quantity" value={productData.quantity} onChange={handleInputChange} placeholder="Quantity" />
            
            <input type="number" name="price" value={productData.price} onChange={handleInputChange} placeholder="Price" />
            <input type="text" name="location" value={productData.location} onChange={handleInputChange} placeholder="Location" />

            <div>
                <label>Category:</label>
                <Select
                    isMulti
                    options={productCategory}
                    onChange={handleCategoryChange}
                />
            </div>

            <div>
                <label>Images:</label> <br />
                <input type="file" multiple onChange={handleImageChange} />
            </div>

            <div>
                <label>Descriptions:</label>
                {productData.descriptions.map((description, index) => (
                    <div key={index} className=''>
                        <input type="text" className='w-1/5' name="title" value={description.title} onChange={(e) => handleDescriptionChange(e, index)} placeholder="Title" />
                        <input type="text" className='w-3/5' name="body" value={description.body} onChange={(e) => handleDescriptionChange(e, index)} placeholder="Body" />
                        <button type="button" className='bg-lime-500 p-3 m-2 text-white font-semibold text-sm rounded text-center sm:inline-block block' onClick={() => removeDescriptionField(index)}>Remove</button>
                    </div>
                ))}
                <button type="button" className='bg-lime-500 p-3 m-2 text-white font-semibold text-sm rounded text-center sm:inline-block block' onClick={addDescriptionField}>Add Description</button>
            </div>
            <button className='bg-lime-900 p-3 m-2 text-white font-semibold text-sm rounded text-center sm:inline-block block' type="submit">Submit</button>
        </form>
        </>:
        <>
        {/* <Navigate to={`/profile/${userid}`}/> */}
        <Navigate to={`/login`} replace={true}/>
        </>}
    </>;
};

export default AddProductForm;