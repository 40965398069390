import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import ProductCard from "../Product/ProductCard";
const baseUrl = process.env.REACT_APP_API_URL


export default function ProductList(){
    const {userid} = useParams();
    const [productlistd, setProductListD] = useState()
    useEffect(()=>{
        axios.get(`${baseUrl}/api/user/products/${userid}/`).then(d=>{
            setProductListD(d.data)
        }).catch(e=>{
        })
    },[userid])
    return <>
        <ProductCard product={productlistd} apiUrl={baseUrl} />
    </>
}