import { useEffect, useState } from "react";
import BlogCard from "./BlogCard";
import axios from "axios";
import { useParams } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_URL

export default function BlogCardList(){
    const {userid}= useParams()
    const [blogs, setBlogs]=useState()
    useEffect(()=>{
        axios.get(`${apiUrl}/api/blogs/${userid}`).then(
            (data)=>{
                setBlogs(data.data)
            }
        ).catch(e=>{})
    },[userid])
    return <>
    <BlogCard blogs={blogs} apiUrl={apiUrl}/>
    </>
}