import { format } from "date-fns";
import UserPopoverAvatar from "./UserPopoverAvatar";
export default function UserRibbon({ user, userid }) {
  return <>
    <div className="flex items-center mb-4">
      <UserPopoverAvatar userid={userid} user={user} />
      <div className="font-medium dark:text-white">
        <p>{user?.user.first_name} {user?.user.last_name}<time className="block text-sm text-gray-500 dark:text-gray-400">Joined on {user?.user.date_joined && format(user?.user.date_joined, 'MMM d, yy hh:mm a')} </time></p>
      </div>
    </div>
  </>
}