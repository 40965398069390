import { useParams, useNavigate } from "react-router-dom"
import axios from 'axios'
import { useEffect, useState } from "react"
import {toast} from 'react-toastify'
export default function PasswordResetConfirm() {
    const {uidb64, token} = useParams()
    const apiUrl = process.env.REACT_APP_API_URL
    const [valid, setIsValid] = useState(true)
    const [pwd, setPwd] = useState()
    const [pwd2, setPwd2] = useState()
    const [err, setErr] = useState("An error occurred")
    const navigate=useNavigate()
    useEffect(()=>{
    axios.get(`${apiUrl}/api/password-reset/confirm/${uidb64}/${token}/`).then((res)=>{
        setIsValid(true)
    }).catch(e=>{
        setIsValid(false)
    })
    },[uidb64, token,apiUrl ])
    const handleResetPassword = (e) => {
        e.preventDefault()
        const formdata = new FormData()
        formdata.append('pwd', pwd)
        formdata.append('pwd2', pwd2)
        toast.promise(
            new Promise((resolve, error) => {
                axios.post(`${apiUrl}/api/password-reset/confirm/${uidb64}/${token}/`, formdata).then((res) => {
                    if (res.status === 200 ) {
                        resolve()
                        navigate('/password_reset_complete')
                    }
                    else{
                        console.log(res.data.error)
                        error()
                    }
                }).catch(e => {   
                    setErr(e.response.data.error)                        
                    error()
                })

            }), {
            pending: "Please Wait...",
            error: err,
            success: "Password was reset Successfully"
        }
        )
    }
    return <>
        {valid?
        <div className="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
            <h1 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Create your password
            </h1>
            <form className="mt-4 space-y-4 lg:mt-5 md:space-y-5" action="" onSubmit={handleResetPassword}>
                <div>
                    <label htmlFor="pwd" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your Password</label>
                    <input type="password" onChange={(e)=>{setPwd(e.target.value)}} name="pwd" id="pwd" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-lime-500 dark:focus:border-lime-500" placeholder="********" required />
                </div>
                <div>
                    <label htmlFor="pwd2" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm Your Password</label>
                    <input type="password" onChange={(e)=>{setPwd2(e.target.value)}} name="pwd2" id="pwd2" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-lime-500 dark:focus:border-lime-500" placeholder="********" required />
                </div> 
                <button type="submit" className="w-full text-white bg-lime-600 hover:bg-lime-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-lime-600 dark:hover:bg-lime-700 dark:focus:ring-primary-800">Reset password</button>
            </form>
        </div>:<div className="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
            <h1 className="mb-1 text-xl font-bold leading-tight tracking-tight text-red-900 md:text-2xl dark:text-white">
               Invalid password reset link!
            </h1>
            </div>
}

    </>
}