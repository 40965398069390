import axios from "axios";
import { useEffect, useState } from "react";
import { NavLink, Outlet, useParams } from "react-router-dom";


const baseUrl = process.env.REACT_APP_API_URL
export default function Profile() {
  const {userid} = useParams();
  const [userdata, setUserData] = useState()
  useEffect(()=>{
    axios.get(`${baseUrl}/api/user/${userid}/`).then(d=>{
      setUserData(d.data)
    }).catch(e=>{
    })
  },[userid])
    return <>

<main className="bg-gray-100 bg-opacity-25 pt-28" style={{minHeight:'90vh'}}>
  <div className=" mb-8">
    <header className="lg:w-8/12 lg:mx-auto flex flex-wrap items-center p-4 md:py-8">
      <div className="md:w-3/12 md:ml-16">
        <img className="w-20 h-20 md:w-40 md:h-40 object-cover rounded-full
               border-2 border-lime-600 p-1" src={`${baseUrl}${userdata?.avatar}`} alt="profile" />
      </div>
      <div className="w-8/12 md:w-7/12 ml-4">
        <div className="md:flex md:flex-wrap md:items-center mb-4">
          <h2 className="text-3xl inline-block font-light md:mr-2 mb-2 sm:mb-0">
            {userdata?.user.first_name} {userdata?.user.last_name}
          </h2>
          <span className="inline-block fas fa-certificate fa-lg text-lime-500 
                         relative mr-6 text-xl transform -translate-y-2" aria-hidden="true">
            <i className="fas fa-check text-white text-xs absolute inset-x-0
                         ml-1 mt-px" />
          </span>
          <a href="#!" className="bg-lime-500 px-2 py-1 text-white font-semibold text-sm rounded text-center sm:inline-block block">Chat</a>
        </div>
        <ul className="hidden md:flex space-x-8 mb-4">
          <li>
            <span className="font-semibold"> {userdata?.product_count} </span>
            products
          </li>
          <li>
            <span className="font-semibold"> {userdata?.blog_count} </span>
            blogs
          </li>
        </ul>
        <div className="hidden md:block">
          <h1 className="font-semibold"> {userdata?.user.email} </h1>
          <p>{userdata?.bio}</p>
        </div>
      </div>
      {/* user meta form small screens */}
      <div className="md:hidden text-sm my-2">
        <h1 className="font-semibold"> {userdata?.user.email} </h1>
        <p>{userdata?.bio}</p>
      </div>
    </header>
    <div className="px-px md:px-3">
      <ul className="flex md:hidden justify-around space-x-8 border-t 
          text-center p-2 text-gray-600 leading-snug text-sm">
        <li>
          <span className="font-semibold text-gray-800 block">{userdata?.product_count}</span>
          products
        </li>
        <li>
          <span className="font-semibold text-gray-800 block">{userdata?.blog_count}</span>
          blogs
        </li>
      </ul>

      {/* Tabs */}
      <ul className="flex items-center justify-around md:justify-center space-x-12  
              uppercase tracking-widest font-semibold text-xs text-gray-600
              border-t">
        {/* posts tab is active */}
        
        <li className="">
          <NavLink className={({ isActive }) => (isActive ? "inline-block p-3 md:border-t md:border-gray-700 md:-mt-px md:text-gray-700" : "inline-block p-3")}  to="products">
            <i className="fas fa-th-large text-xl md:text-xs" />
            <span className="hidden md:inline">Products</span>
          </NavLink>
        </li>
        <li>
          <NavLink className={({ isActive }) => (isActive ? "inline-block p-3 md:border-t md:border-gray-700 md:-mt-px md:text-gray-700" : "inline-block p-3")} to="blogs">
            <i className="far fa-square text-xl md:text-xs" />
            <span className="hidden md:inline">Blogs</span>
          </NavLink>
        </li>
        <li>
          <NavLink className={({ isActive }) => (isActive ? "inline-block p-3 md:border-t md:border-gray-700 md:-mt-px md:text-gray-700" : "inline-block p-3")} to="new">
            <i className="far fa-square text-xl md:text-xs" />
            <span className="hidden md:inline">New product </span>
          </NavLink>
        </li>
        <li>
          <NavLink className={({ isActive }) => (isActive ? "inline-block p-3 md:border-t md:border-gray-700 md:-mt-px md:text-gray-700" : "inline-block p-3")} to="new_blog">
            <i className="far fa-square text-xl md:text-xs" />
            <span className="hidden md:inline">New Blog </span>
          </NavLink>
        </li>
        <li>
          <NavLink className={({ isActive }) => (isActive ? "inline-block p-3 md:border-t md:border-gray-700 md:-mt-px md:text-gray-700" : "inline-block p-3")} to="edit_profile">
            <i className="far fa-square text-xl md:text-xs" />
            <span className="hidden md:inline">Edit Profile </span>
          </NavLink>
        </li>
      </ul>
      <div className="flex flex-wrap w-full">
        <Outlet/>
      </div>
      {/* For you */}
    </div>
  </div>
</main>

    </>
}