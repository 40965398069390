import { Link } from "react-router-dom";
import RatingFilter from "./RatingsFilter";
// import ButtonOutlineGreenBlue from "../Buttons/ButtonOutlineGreenBlue";

export default function ProductCard({ product, apiUrl }) {
  return <>
    {product?.map((product, key) => (

      <div key={key} className="lg:w-1/4 md:w-1/2 px-3 mb-6">
        <div className="flex w-full h-full flex-wrap bg-lime-800 overflow-hidden rounded">
          <div className="w-full">
          <Link to={`/products/${product.id}`}>
            <img className="object-cover h-full w-full" src={`${apiUrl}${product.image[0].image}`} style={{height:'300px'}} alt={product.title} />
          </Link>
          </div>
          <div className="w-full p-5">
            <h2 className="text-white leading-normal text-lg">{product.title} {product.quantity}</h2>
            <div className="flex flex-wrap justify-between items-center mt-6">
              <div className="inline-flex items-center">
                <div className="w-10 h-10 text-red-800 rounded-full overflow-hidden flex-shrink-0">
                  new
                </div>
                <div className="flex-1 pl-2">
                  <h2 className="text-white mb-1">Ksh. {product.price}</h2>
                  <p className="text-xs flex"><RatingFilter ratings={product.ratings} /></p>
                </div>
              </div>
              <span className="">
                <button className="bg-white p-1 rounded">wishlist</button>
              </span>
            </div>
          </div>
        </div>
      </div>

    ))}
  </>
}