export default function BubbleLeft({msgs}) {
    return <>
        <div className="flex items-start gap-2.5 px-3 py-1">
            <img className="w-8 h-8 rounded-full" src={msgs.sender_profile.avatar} alt="Profile avatar" />
            <div className="flex flex-col w-full max-w-[320px] leading-1.5 p-4 border-gray-200 bg-gray-100 rounded-e-xl rounded-es-xl dark:bg-gray-700">
                <div className="flex items-center space-x-2 rtl:space-x-reverse">
                    <span className="text-sm font-semibold text-gray-900 dark:text-white">{msgs.sender_profile.user.first_name} {msgs.sender_profile.user.last_name}</span>
                    <span className="text-sm font-normal text-gray-500 dark:text-gray-400">11:46</span>
                </div>
                <p className="text-sm font-normal py-2.5 text-gray-900 dark:text-white">{msgs.message}</p>
            </div>
           
        </div>
    </>
}