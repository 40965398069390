import { Outlet } from 'react-router-dom';
import Nav from '../components/Nav/Nav';
import Footer from '../components/Footer/Footer';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import Offcanvas from '../components/Offcanvas/Offcanvas';
export default function Base() {
    const isAuthenticated = useIsAuthenticated()
    console.log("MyBad")
    return <>
        <Nav />
        <Outlet />
        <Footer />
      {
      isAuthenticated()?
      <Offcanvas isAuthenticated ={isAuthenticated() }/>
       :<></>
    }
    </>
}