import ProductCard from "./ProductCard";
const apiUrl=process.env.REACT_APP_API_URL;
export default function ProductListing({products}){
    return<>

    
    <section className="bg-white dark:bg-gray-900" id="shopnow">
        <div className="max-w-screen-xl px-4 py-8 mx-auto sm:py-16 lg:px-6">
          <div className="max-w-screen-md mb-8 lg:mb-16">
            <h2 className="mb-4 text-4xl font-extrabold tracking-tight text-gray-900 dark:text-white">Shop Now</h2>
            <p className="text-gray-500 sm:text-xl dark:text-gray-400">Go on! it's all yours</p>
          </div>
          {/* <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0 flex"> */}
          <div className="flex flex-wrap md:-mx-3">
          <ProductCard product={products} apiUrl={apiUrl} />            

          </div>         
        </div>
      </section>
    </>
}