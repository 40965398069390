import RatingFilter from "../Product/RatingsFilter";
import UserRibbon from "./UserRibbon";
import { format } from 'date-fns';

export default function Comments({ comments }) {
  return <>

    {comments?.map((acomment, key) => (

      <article className="m-3 bg-lime-50 p-3" key={key}>
        <UserRibbon userid={`comment${acomment.user.user.id}`} user={acomment.user} />

        <div className="flex items-center mb-1 space-x-1 rtl:space-x-reverse">
          <RatingFilter ratings={acomment.stars} />
          <h3 className="ms-2 text-sm font-semibold text-gray-900 dark:text-white">{acomment.title}</h3>
        </div> 
        <footer className="mb-5 text-sm text-gray-500 dark:text-gray-400"><p>Reviewed on <time>{acomment?.date&&format(acomment?.date, 'MMM d, yy hh:mm a')}</time></p></footer>
        <p className="mb-2 text-gray-500 dark:text-gray-400">{acomment.body}</p>
        <aside>
          <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">2 people found this helpful</p>
          <div className="flex items-center mt-3">
            <a href="#!" className="px-2 py-1.5 text-xs font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Helpful</a>
            {/* <a href="#" className="ps-4 text-sm font-medium text-blue-600 hover:underline dark:text-blue-500 border-gray-200 ms-4 border-s md:mb-0 dark:border-gray-600">Report abuse</a> */}
          </div>
        </aside>
      </article>

    ))}


  </>
}