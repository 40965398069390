import { format } from "date-fns";
import { Link } from "react-router-dom";

export default function BlogCard({ blogs, apiUrl }) {
  return <>
    {blogs?.map((blog, key) => (
      <div className="md:w-1/2 px-3 mb-6 w-full">
      <Link to={`/blog/${blog?.id}`}>
        {console.log(blog)}
        <div className="flex w-full h-full flex-col flex-wrap bg-cover bg-no-repeat bg-center p-5 rounded overflow-hidden" style={{ backgroundImage:`url(${apiUrl}${blog?.image})`,textShadow:'1px 1px 2px black', fontSize:'1.3em' }}>
          <h2 className="text-white text-lg mb-2">{blog?.title}</h2>
          <p className=" text-lime-700">{blog?.preview}</p>
          <div className="flex flex-wrap justify-between items-center mt-auto pt-6">
            <div className="inline-flex items-center">
              <div className="w-10 h-10 rounded-full overflow-hidden flex-shrink-0">
                <img src={`${apiUrl}${blog?.user.avatar}`} alt="arthur" />
              </div>
              <div className="flex-1 pl-2">
                <h2 className="text-white mb-1">{blog?.user.user.first_name} {blog?.user.user.last_name}</h2>
                <p className="  text-xs text-lime-700">{ format(blog.date, 'yyyy MMM dd')}</p>
              </div>
            </div>
            <span className="text-white opacity-50">
              
            </span>
          </div>
        </div>
      </Link>
      </div>
    ))}
  </>
}