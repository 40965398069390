import useSignOut from 'react-auth-kit/hooks/useSignOut'
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
// import Offcanvas from '../Offcanvas/Offcanvas'
export default function Nav() {
  const isAuthenticated = useIsAuthenticated()
  const navigate = useNavigate()
  const signOut = useSignOut()
  return (
    <>
      <nav className="border-gray-200 dark:bg-gray-900 bg-white border-b fixed w-full z-30">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <NavLink to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
            <img src="https://arthurom254.github.io/icon.png" className="h-8" alt="Waste marketplace Kenya Logo" />
            <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">WMP</span>
          </NavLink>
          <button data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
            <span className="sr-only">Open main menu</span>
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
            </svg>
          </button>
          <div className="hidden w-full md:block md:w-auto" id="navbar-default">
            <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <NavLink to='#!' className="block py-2 px-3 text-white bg-lime-700 rounded md:bg-transparent md:text-lime-700 md:p-0 dark:text-white md:dark:text-lime-500" aria-current="page">Home</NavLink>
              </li>
              <li>
                <NavLink to='#!' className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-lime-700 md:p-0 dark:text-white md:dark:hover:text-lime-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">About</NavLink>
              </li>
              <li>
                <NavLink to='#contucts' className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-lime-700 md:p-0 dark:text-white md:dark:hover:text-lime-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Contucts</NavLink>
              </li>

              {isAuthenticated() ?
                <>
                  <li>
                    <NavLink to='#!' onClick={() => { signOut(); navigate(window.location.pathname) }} className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-lime-700 md:p-0 dark:text-white md:dark:hover:text-lime-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Logout</NavLink>
                  </li>
              <li>
                <NavLink to={`/profile/${jwtDecode(Cookies.get('_auth')).user_id}`} className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-lime-700 md:p-0 dark:text-white md:dark:hover:text-lime-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Profile</NavLink>
              </li>
                  <li>
                  <button className="text-white bg-lime-700 hover:bg-lime-800 focus:ring-4 focus:ring-lime-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-lime-600 dark:hover:bg-lime-700 focus:outline-none dark:focus:ring-lime-800" type="button" data-drawer-target="drawer-right-example" data-drawer-show="drawer-right-example" data-drawer-placement="right" aria-controls="drawer-right-example">
                Messages
            </button>
                  </li>
                </> :
                <li>
                  <Link to='/login' className="text-white bg-lime-700 hover:bg-lime-800 focus:ring-4 focus:ring-lime-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-lime-600 dark:hover:bg-lime-700 focus:outline-none dark:focus:ring-lime-800" type="button">
                    Login
                  </Link>

                </li>
              }

            </ul>
          </div>
        </div>
      </nav>
      
    </>
  )
}