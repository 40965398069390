import UserRibbon from "../User/UserRibbon";

export default function Description({description, user}) {
    return <>
        <section className="mx-4">
            <UserRibbon userid={`description${user?.user.id}`}  user={user}/>

            <div id="accordion-flush" data-accordion="collapse" data-active-classes="bg-white dark:bg-gray-900 text-gray-900 dark:text-white" data-inactive-classes="text-gray-500 dark:text-gray-400">
            {description?.map((desc, key)=>(
                <span key={key}>
                <h2 id={`desc-accordion-flush-heading-${key}`}>
                    <button type="button" className="flex items-center justify-between w-full py-5 font-medium rtl:text-right text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400 gap-3" data-accordion-target={`#desc-accordion-flush-body-${key}`} aria-expanded={key===0?"true":"false"} aria-controls={`desc-accordion-flush-body-${key}`}>
                        <span>{desc.title}</span>
                        <svg data-accordion-icon className="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5 5 1 1 5" />
                        </svg>
                    </button>
                </h2>
                <div id={`desc-accordion-flush-body-${key}`} className="hidden" aria-labelledby={`desc-accordion-flush-heading-${key}`}>
                    <div className="py-5 border-b border-gray-200 dark:border-gray-700">
                        <p className="mb-2 text-gray-500 dark:text-gray-400">{desc.body}</p>
                    </div>
                </div>
                </span>

            ))}
                
            </div>


        </section>


    </>
}