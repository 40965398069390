import { Link, Outlet } from "react-router-dom";

export default function AuthBase() {
    return <>
        <section className="bg-gray-50 bg-no-repeat bg-cover bg-[url('https://picsum.photos/800/500')] dark:bg-gray-900 h-screen">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <Link to="/" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                    <img className="w-8 h-8 mr-2" src="https://arthurom254.github.io/icon.png" alt="arthur waste marketplace" />
                    WMP
                </Link>

                <Outlet/>
            </div>

        </section>
    </>
}