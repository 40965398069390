import axios from "axios";
import BubbleLeft from "./BubbleLeft";
import BubbleRight from "./BubbleRight";
import InboxList from "./InboxList";
import {  useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
const apiUrl=process.env.REACT_APP_API_URL

export default function Offcanvas({isAuthenticated }) {

    const [currentUser, setCurrentUser]=useState(0)
    const [openMsg, setOpenMsg]=useState(false);
    const [messagesInbox, setMessagesInbox]=useState([])
    const [messagesChat, setMessagesChat]=useState([])
    const [message, setMessage]=useState([])

    useEffect(()=>{
    if(isAuthenticated){
        setCurrentUser(jwtDecode(Cookies.get('_auth')).user_id);
        setInitMsg()
    }},[isAuthenticated])

    async function setInitMsg(){
        try{
            const res=axios.get(`${apiUrl}/api/inbox/`)
            const data=(await res).data
            setMessagesInbox(data)     
        }catch(e){}   
    }
    
    async function loadMyChat(uid){
        try{
            const res=await axios.get(`${apiUrl}/api/chat/${uid}`);
            const msgs=(await res).data;
            setMessagesChat(msgs);
        }catch(e){}  
    }

    const openMsgFunc=(args,uid)=>{
        setOpenMsg(args);
        
        // loadMyChat(uid);
        const intervalId = setInterval(() => {
            loadMyChat(uid);
          }, 1000); 
    }

    const sendMsg = async (e)=>{
        e.preventDefault()
        try{
        const formData = new FormData()
        formData.append('user', currentUser)
        formData.append('sender', currentUser)
        formData.append('receiver', localStorage.getItem('to'))
        formData.append('message', message)
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/send/`,formData);
        console.log(response.data);
        setMessage('')
        }catch(e){}  
    }

    const handleMsgChange = (event)=>{
        setMessage(event.target.value);
        console.log(message);
    }

    return (
        
        <>
        <div id="drawer-right-example" className="fixed top-0 right-0 z-40 h-screen pb-4 overflow-y-auto transition-transform translate-x-full bg-white w-80 dark:bg-gray-800 border" tabIndex={-1} aria-labelledby="drawer-right-label">
            
            <span className="p-2 bg-lime-800 block top-0 sticky">
                <h5 id="drawer-right-label" className="inline-flex items-center mb-4 text-base font-semibold text-gray-500 dark:text-gray-400"><svg className="w-4 h-4 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                {openMsg&&
                    <path onClick={()=>{setOpenMsg(false,0)}} d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                }
                </svg>Messages</h5>
                <button type="button" data-drawer-hide="drawer-right-example" aria-controls="drawer-right-example" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white">
                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                    </svg>
                    <span className="sr-only">Close menu</span>
                </button>
            </span>
            <span className="">
                {openMsg?
                
    <>
    <div className="overflow-y-auto ">
        {messagesChat.map((msgs, key)=>(
        currentUser===msgs.sender?
        <span key={key}><BubbleRight msgs={msgs}  /></span>
        : <span key={key}><BubbleLeft  msgs={msgs} /></span>            
        ))}
    </div>
    <form className="bottom-0 sticky z-10" onClick={sendMsg}>
        <label htmlFor="chat" className="sr-only">Your message</label>
        <div className="flex items-center px-3 py-2 rounded-lg bg-gray-50 dark:bg-gray-700">
            <textarea  onChange={handleMsgChange} id="chat" rows={1} className="block mx-4 p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-lime-500 focus:border-lime-500 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-lime-500 dark:focus:border-lime-500" placeholder="Your message..." defaultValue={""} >{{message}}</textarea>
            <button type="submit" className="inline-flex justify-center p-2 text-lime-600 rounded-full cursor-pointer hover:bg-lime-100 dark:text-lime-500 dark:hover:bg-gray-600">
                <svg className="w-5 h-5 rotate-90 rtl:-rotate-90" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                    <path d="m17.914 18.594-8-18a1 1 0 0 0-1.828 0l-8 18a1 1 0 0 0 1.157 1.376L8 18.281V9a1 1 0 0 1 2 0v9.281l6.758 1.689a1 1 0 0 0 1.156-1.376Z" />
                </svg>
                <span className="sr-only">Send message</span>
            </button>
        </div>
    </form>
</>
                // <ChatBox messagesChat={messagesChat} currentUser={currentUser} sendMsg={sendMsg}/>
                :
                <InboxList openMsgFunc={openMsgFunc} messagesInbox={messagesInbox} currentUser={currentUser}/>}
            </span>
        </div>
        </>

    )
}