
import { useParams } from "react-router-dom";
import ButtonOutlineGreenBlue from "../Buttons/ButtonOutlineGreenBlue";
import CommentForm from "../User/CommentForm";
import Comments from "../User/Comments";
import Description from "./Description";
import ProductCard from "./ProductCard2";
// import Error404 from "../../pages/Errojs/404"
import Slideshow from "./Slideshow";
import { useEffect, useState } from "react";
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;
export default function ProductMore() {
    const [result, setResult] = useState({})
    const { productid } = useParams()
    useEffect(() => {

        axios.get(`${apiUrl}/api/products/${productid}`).then(data => {
            setResult(data.data);
        }).catch((e) => {
        });
        try{
            window.scrollTo({top:0, behavior: 'smooth' }) 
        }catch(e){} 
    }, [productid])
    return <>
            <section>
                <div className='lg:flex md:gap-10 	mx-2 pt-32'>
                    <div className="overflow-hidden lg:w-3/4">
                        <Slideshow images={result?.image} apiUrl={apiUrl} />
                        <Description description={result?.description} user={result?.seller} />
                        <section>
                            <Comments comments={result?.comments} />
                            <div className="mx-auto w-full text-center">
                                <ButtonOutlineGreenBlue label="Load More Comments" />
                            </div>
                            <CommentForm />
                        </section>
                    </div>
                    <div className="col-span-2 overflow-auto  lg:w-1/4">
                        <ProductCard product={result?.related_products} apiUrl={apiUrl} />
                    </div>
                </div>
            </section>

    </>
}
