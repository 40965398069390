import axios from "axios";
import { useState } from "react";

export default function Test(){
    const [mydata, setMyData] = useState({
        'test_subject1':'',
        'test_subject2':'',
        'test_subject3':''
    })
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setMyData({ ...mydata, [name]: value });
    };
    const handleSubmit = async (e)=>{
        e.preventDefault()
        const formData=new FormData()
        formData.append('test_subject1', mydata.test_subject1)
        formData.append('test_subject2', mydata.test_subject2)
        formData.append('test_subject4', 'mydata.test_subject2')
        formData.append('test_subject3', mydata.test_subject3)

        const response2 = await axios.post(`${process.env.REACT_APP_API_URL}/api/product_new/` , formData, {
            headers: {
                // 'Content-Type': 'multipart/form-data'
            }
        });
    }
    return <>
    <h1>Test post </h1>
    <form onSubmit={handleSubmit}>
        <input type="text" onChange={handleInputChange} name="test_subject1" id="" />
        <input type="text" onChange={handleInputChange} name="test_subject2" id="" />
        <input type="text" onChange={handleInputChange} name="test_subject3" id="" />
        <input type="submit" value="Post" />
    </form>
    </>
}