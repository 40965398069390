import { Link } from 'react-router-dom'
import P404 from '../../images/p404.gif'
export default function Error404() {
    return <>
        <div className="bg-indigo-900 relative overflow-hidden h-screen">
            <img src={P404} className="absolute h-full w-full object-cover" alt="404"/>
            <div className="inset-0 bg-black opacity-25 absolute">
            </div>
            <div className="container mx-auto px-6 md:px-12 relative z-10 flex items-center py-32 xl:py-40">
                <div className="w-full font-mono flex flex-col items-center relative z-10">
                    <h1 className="font-extrabold text-5xl text-center text-white leading-tight mt-4">
                        You are probably lost😒
                    </h1>
                    <p className="font-extrabold text-5xl my-44 text-white animate-bounce hover:text-lime-700">                        
                        <Link to='/'>Go Back Home</Link>
                    </p>
                </div>
            </div>
        </div>


    </>
}