import { Link } from "react-router-dom";
import RatingFilter from "./RatingsFilter";

export default function ProductCard({ product, apiUrl }) {
  return <>
    {product?.map((product, key) => (
      <div key={key} className="w-full max-w-sm  border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mx-auto">
        <Link to={`/products/${product.id}`}>
          <img className="p-0 rounded-t-lg h-full" src={`${apiUrl}${product.image[0].image}`} alt="product" />
        </Link>
        <div className="px-5 pb-5">
          <Link to={`/products/${product.id}`}>
            <h5 className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white">{product.title} {product.quantity}</h5>
          </Link>
          <div className="flex items-center mt-2.5 mb-5">
            <div className="flex items-center space-x-1 rtl:space-x-reverse">
              <RatingFilter ratings={product.ratings} />
            </div>
            <span className="bg-lime-100 text-lime-800 text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-lime-200 dark:text-lime-800 ms-3">{product.rating_count}</span>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-3xl font-bold text-gray-900 dark:text-white">Ksh. {product.price}</span>
            <Link to={`/products/${product.id}`} className="text-white bg-lime-700 hover:bg-lime-800 focus:ring-4 focus:outline-none focus:ring-lime-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-lime-600 dark:hover:bg-lime-700 dark:focus:ring-lime-800">Add to wishlist</Link>
          </div>
        </div>
      </div>

    ))}
  </>
}