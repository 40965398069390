import { useState } from "react"
import { Link, useNavigate} from "react-router-dom"
import { toast } from "react-toastify";
import axios from 'axios';
const baseUrl = process.env.REACT_APP_API_URL;
export default function PasswordReset() {
    const [email, setEmail]= useState()
    const [err, setErr] = useState("An error occurred!")
    const navigate=useNavigate()
    const handleResetPassword = (e) => {
        e.preventDefault()
        const formdata = new FormData()
        formdata.append('email', email)
        toast.promise(
            new Promise((resolve, error) => {
                axios.post(`${baseUrl}/api/password-reset/`, formdata).then((res) => {
                    if (res.status === 200 ) {
                        resolve()
                        navigate('/password_reset_done')
                        // return <Navigate to="/password_reset_done" replace={true} />
                    }
                    else{
                        console.log(res.data.error)
                        error()
                    }
                }).catch(e => {   
                    setErr(e.response.data.error)                        
                    error()
                })

            }), {
            pending: "Please Wait...",
            error: err,
            success: "Email sent Successfully"
        }
        )
    }
    // const resetPwd = (e)=>{
    //     e.preventDefault()
    //     toast.promise((resolve, error)=>{
    //         const formdata=new FormData()
    //         formdata.append("email", email );
    //         axios.post(`${apiUrl}/api/password-reset/`, formdata).then(
    //             (res)=>{
    //                 if(res.status === 200){
    //                     resolve()
    //                     return <Navigate to ="/" />
    //                 }else{
    //                     error()
    //                 }
    //             }
    //         ).catch(e=>{
    //             error()
    //         });
    //     },{
    //         pending:"Loading...",
    //         success:"Email sent successfully",
    //         error:"Could not send the email"
    //     }
    // )
    //     alert(email)
    // }
        
    return <>

        <div className="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
            <h1 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Forgot your password?
            </h1>
            <p className="font-light text-gray-500 dark:text-gray-400">Don't fret! Just type in your email and we will send you a code to reset your password!</p>
            <form className="mt-4 space-y-4 lg:mt-5 md:space-y-5" action="" onSubmit={handleResetPassword}>
                <div>
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                    <input type="email" name="email" id="email" value={email} onChange={(e)=>{setEmail(e.target.value)}} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-lime-500 dark:focus:border-lime-500" placeholder="name@company.com" required />
                </div>
                <div className="flex items-start">
                    <div className="flex items-center h-5">
                        <input id="terms" aria-describedby="terms" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required />
                    </div>
                    <div className="ml-3 text-sm">
                        <label htmlFor="terms" className="font-light text-gray-500 dark:text-gray-300">I accept the <Link className="font-medium text-primary-600 hover:underline dark:text-primary-500" to="#">Terms and Conditions</Link></label>
                    </div>
                </div>
                <button type="submit" className="w-full text-white bg-lime-600 hover:bg-lime-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-lime-600 dark:hover:bg-lime-700 dark:focus:ring-primary-800">Reset password</button>
            </form>
        </div>

    </>
}