// import { useLoaderData } from "react-router-dom";
import AboutUs from "../components/About/AboutUs";
import ProductListing from "../components/Product/Listing";
// import { Bounce, toast } from "react-toastify";
import { useEffect, useState } from "react";
import axios from "axios";
const apiUrl=process.env.REACT_APP_API_URL
export default function Home() {
    const [products, setProducts] = useState({})
    useEffect(()=>{
        axios.get(`${apiUrl}/api/products/`).then(data=>{
            setProducts(data.data)
        }).catch((e)=>{})
    },[])
    return <>
        <AboutUs />
        <ProductListing products={products?.products} />
    </>
}
