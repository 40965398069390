import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import Error404 from "../../pages/Errojs/404"
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL
export default function BlogReader(){
    const {blogid} = useParams()
    const [blog, setBlogs]=useState()
    useEffect(()=>{
        axios.get(`${apiUrl}/api/blog/${blogid}`).then(
            (data)=>{
                setBlogs(data.data)
            }
        ).catch(e=>{
            return <Error404/>
        })
    },[blogid])
    return <>
  <div className="max-w-screen-xl mx-auto p-8 sm:p-10 md:p-16">
    
    <div className="bg-cover bg-center h-64 pt-28" style={{height: 450, backgroundImage: `url('${apiUrl}${blog?.image}')`}} title={blog?.title}>
    </div>
    <div className="max-w-2xl mx-auto">
      <div className="mt-3 bg-white rounded-b lg:rounded-b-none lg:rounded-r flex flex-col justify-between leading-normal">
        <a href="#!">
          <img className="rounded-full relative top-0 -mt-16 mx-auto h-24 w-24 border-4" alt={blog?.title} src={`${apiUrl}${blog?.user.avatar}`} /></a>
        <div className="mt-5 text-center">
          {/* <a href="#" className="text-xs text-indigo-600 uppercase font-medium hover:text-gray-900 transition duration-500 ease-in-out">
            Election
          </a>, <a href="#" className="text-xs text-indigo-600 uppercase font-medium hover:text-gray-900 transition duration-500 ease-in-out">
            Politics
          </a> */}
          <h1 href="#!" className="text-gray-900 font-bold text-3xl mb-2">{blog?.title}</h1>
          <p className="text-gray-700 text-xs mt-2">Written By: <Link to={`/profile/${blog?.user.id}`} className="text-indigo-600 font-medium hover:text-gray-900 transition duration-500 ease-in-out">
          {blog?.user.user.first_name} {blog?.user.user.last_name}
            </Link></p>
        </div>
        <div className>
          <span className="text-base leading-8 my-5">
            {blog?.body}
            </span>
            <h2>Upvote</h2>
        </div>
      </div>
    </div>
  </div>
  </>
}