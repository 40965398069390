import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider, Outlet } from 'react-router-dom'
import Home from './pages/Home';
import Base from './pages/Base';
import ProductMore from './components/Product/ProductMore';
import LoginPg from './components/Auth/Login';
import SignupPg from './components/Auth/Register';
import PasswordReset from './components/Auth/PasswordReset';
import AuthBase from './components/Auth/AuthBase';
import PasswordResetDone from './components/Auth/PasswordResetDone';
import PasswordResetConfirm from './components/Auth/PasswordResetConfirm';
import PasswordResetComplete from './components/Auth/PasswordResetComplete';
import MyProfile from './pages/MyProfile';
import Profile from './pages/Profile';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Error404 from './pages/Errojs/404';
import Cookies from 'js-cookie';
import axios from 'axios';
import ToastAlert from './components/Notifications/ToastAlert';
import 'flowbite/dist/flowbite';
import ProductList from './components/User/ProductList';
import BlogCardList from './components/Product/BlogCardList';
import BlogReader from './components/Product/BlogReader';
import NewProduct from './components/Product/NewProduct';
import Test from './pages/Test';
import RichTextEditor from './components/Blog/Editor';
function App() {

  // useEffect(()=>{
  //   initFlowbite();
  // },[])
  if(Cookies.get('_auth')){
    const token =Cookies.get('_auth')
    console.log(token)
    axios.defaults.headers.common.Authorization= `Bearer ${token}`;
  }
 
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path='' element={<Outlet />}>
        <Route path='my' element={<MyProfile />} />
        <Route path='' element={<Base />} >
          <Route index element={<Home />} />
          <Route path='products/:productid'  element={<ProductMore />} />
          <Route path='blog/:blogid' element={<BlogReader />} />
          <Route path='profile/:userid' element={<><Profile/></>}>
            <Route path='products' element={<><ProductList/></>}/>
            <Route path='blogs' element={<><BlogCardList/></>}/>
            <Route path='new' element={<><NewProduct /></>}/>
            <Route path='new_blog' element={<><RichTextEditor /></>}/>
          </Route>
        </Route>
        <Route path='' element={<AuthBase />} errorElement={ <ToastAlert/>}>
          <Route path='test' element={<Test/>} />
          <Route path='login' element={<LoginPg />} />
          <Route path='signup' element={<SignupPg />} />
          <Route path='password_reset' element={<PasswordReset />} />
          <Route path='password_reset_done' element={<PasswordResetDone />} />
          <Route path='password_reset_confirm/:uidb64/:token/' element={<PasswordResetConfirm />} />
          <Route path='password_reset_complete' element={<PasswordResetComplete />} />
        </Route>          
        <Route path='*' element={<Error404/>}/>
      </Route>
    )
  );
  return (
    <>
      <ToastContainer/>
      <RouterProvider router={router} />
    </>

  );
}
export default App;
